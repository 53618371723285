import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useAppContext }from "contexts/AppContext"


// Storybook
import { Row, Col, Card,CardBody, Container } from "stories/layout";
import { Input, DatePicker, Checkbox } from "stories/forms";
import { Typography, Button, Link, IconButton, Modal } from "stories/components";
import { TableAuto } from "stories/tables"
import { useNotification } from "stories/components/Notification"




// App
import { PageWrapper, PageHeader, PageContainer } from "components/Page"
import  { IncomeSummaryTable } from "components/Tables"
import { httpGetTransactions, httpGetPrivateStockTransactions, httpGetIncomeReportSummary } from "services/transactions"
import {  httpGetIncomeReportExcel } from "services/transactions"

import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import StockPrivateSelect from "components/Forms/StockPrivateSelect/StockPrivateSelect"
import TransactionTypeSelect from "components/Forms/TransactionTypeSelect/TransactionTypeSelect"
import InvestmentTypeSelect from "components/Forms/InvestmentTypeSelect/InvestmentTypeSelect"
import PortfolioSelectStore from "components/Forms/PortfolioSelect/PortfolioSelectStore"
import PortfolioSelect from "components/Forms/PortfolioSelect/PortfolioSelect"
import SecuritySelect from "components/Forms/SecuritySelect/SecuritySelect"
import TimeRangeSelect from "components/Forms/TimeRangeSelect/TimeRangeSelect"
import SubscriptionOptionsInfo from "components/Info/SubscriptionOptionsInfo.js"
import LicensePayment from "views/vismapay/LicensePayment/LicensePayment"
import UpdateUp from "views/vismapay/UpdateUp/UpdateUp"


import { useHistory } from 'react-router-dom';


import { useAuthContext } from "contexts/AuthContext"

import { getDate } from "services/datetime"
import { roundScaleDecimals, thousandFormat } from "services/helpers"
import { set } from "date-fns";
import { filterEventStoreDefs } from "@fullcalendar/core";

import Info from "components/Info/Info.js";
import { setIn } from "formik";

const TaxReport9A = ({match, setExportButton}) => {
  
  const { t } = useTranslation();
  const { getTransactionType, getPortfolioName,getPortfolioCurrency} = useAppContext()
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const { notify } = useNotification();


  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const [profitSummary, setProfitSummary] = useState();
  const [securityOptions, setSecurityOptions] = useState();
  const account = useStoreState((state) => state.account?.account);
  //use history to redirect to frontpage if no portfolio
  const history = useHistory();
  

  const currentYear = new Date().getFullYear();
  const defaultYear = new Date(currentYear -10, 0,1);
  const [cutOffYear, setCutOffYear] = useState(10);
  const [usePurchaseCostAssumption, setUsePurchaseCostAssumption] = useState(true);
  const [purchaseCostAssumption, setPurchaseCostAssumption] = useState(20);
  const [purchaseCostAssumptionCutOfYear, setPurchaseCostAssumptionCutOfYear] = useState(40);


  const portfolios = useStoreState((state) => state.portfolio?.portfolios);
  const portfoliosLoading = useStoreState((state) => state.portfolio?.loading);

  const [pdfRows, setPdfRows] = useState([])
  const [pdfSummaryRows, setPdfSummaryRows] = useState([])

  
  const handleDateChange = event => {
    setCurrentDate(event.target.value)
  }
  
  // Table
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState([])

  const [filters, setFilters] = useState({
    // limit: 50,
    // offset:0,
    ordering: "-date_transaction",
    // stock_market: "XHEL",
    portfolio: [portfolio?.id],
    selected_portfolios: [{id:portfolio?.id, value:portfolio?.name, name:portfolio?.name}],
    time_range: JSON.stringify({
      key: "last_year",
      value: t("last_year", "Viime vuosi"),
      range: {
        start: new Date(
            new Date().getFullYear()-1, 
            0, 
            1),
        end: new Date(new Date().getFullYear(), 0, 0),
      },
    }),
    date_transaction__date__gte: new Date(
      new Date().getFullYear()-1, 
      0, 
      1),
    date_transaction__date__lte: new Date(new Date().getFullYear(), 0, 0)
  })
  
  const getTransactions = (p,c,sp) => {

    const params = {
      ...filters,
      date_transaction__date__gte: filters?.date_transaction__date__gte ? moment(filters?.date_transaction__date__gte).format("YYYY-MM-DD") : undefined,
      sdate_transaction__date__lte: filters?.date_transaction__date__lte ? moment(filters?.date_transaction__date__lte).format("YYYY-MM-DD") : undefined,
      stock_market: filters?.stock_market ? filters?.stock_market : undefined,
      selected_portfolios: null,
      portfolio: p? p: filters?.portfolio,
      selected_currency: c?c:filters?.selected_currency,
      cutOffYear  : cutOffYear, 
      usePurchaseCostAssumption: usePurchaseCostAssumption,
      purchaseCostAssumption: purchaseCostAssumption,
      purchaseCostAssumptionCutOfYear: purchaseCostAssumptionCutOfYear,
      for_investime: true,

    }
    setLoading(true)

    httpGetIncomeReportSummary(params).then(response => {
        setProfitSummary(response?.data)

        if (response?.data){
            let options=  []
            const sellOptions = response?.data?.sell || []
            const buyOptions = response?.data?.buy || []
            const optionsSet = new Set()
        
            // Add unique sell items to the options set
            sellOptions.forEach(item => {
            const key = `${item.related_model_type}-${item.related_model_id}`
            if (!optionsSet.has(key)) {
                optionsSet.add(key)
                options.push({related_model_type: item?.related_model_type, related_model_id: item?.related_model_id, value:item?.related_model?.[0]?.name})
            }
            })
        
            // Add unique buy items to the options set
            buyOptions.forEach(item => {
            const key = `${item.related_model_type}-${item.related_model_id}`
            if (!optionsSet.has(key)) {
                optionsSet.add(key)
                options.push({related_model_type: item?.related_model_type, related_model_id: item?.related_model_id, value:item?.related_model?.[0]?.name})
            }
            })

            setSecurityOptions(options)
        }
        // console.log(response)
        setLoading(false)
    })
  }
  useEffect(() => {
    if (portfolio?.id){
      setFilters(s => ({
        ...s,
        portfolio: [portfolio?.id],
        selected_portfolios: [{id:portfolio?.id, value:portfolio?.name, name:portfolio?.name}],
      }))
    }
    getTransactions([portfolio?.id], portfolio?.currency_label, [{id:portfolio?.id, value:portfolio?.name, name:portfolio?.name}])

  }, [portfolio?.id])

  // Excel export BEGIN

  const [loadingExport, setLoadingExport] = useState(false)

  const handleExcelExport = (file_type="excel") => {
    

    const params = {
      ...filters,
      date_transaction__date__gte: filters?.date_transaction__date__gte ? moment(filters?.date_transaction__date__gte).format("YYYY-MM-DD") : undefined,
      sdate_transaction__date__lte: filters?.date_transaction__date__lte ? moment(filters?.date_transaction__date__lte).format("YYYY-MM-DD") : undefined,
      stock_market: filters?.stock_market ? filters?.stock_market : undefined,
      selected_portfolios: null,
      export_excel: true,
      client_timezone_offset: Intl.DateTimeFormat().resolvedOptions().timeZone,
      usePurchaseCostAssumption: usePurchaseCostAssumption,
      purchaseCostAssumption: purchaseCostAssumption,
      purchaseCostAssumptionCutOfYear: purchaseCostAssumptionCutOfYear,
      cutOffYear  : cutOffYear,
      tax_9A: true,
      file_type: file_type,
      for_investime: true,
    }
      
    const data ={
      pdf_rows: pdfRows,
      summary_pdf_rows: pdfSummaryRows
    }

    let fileName = "tax9a_report_export.xlsx";

    if (file_type === "pdf"){
      fileName = "tax9a_report_export.pdf";
    }

    
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    
    setLoadingExport(true)
    if(file_type === "pdf"){
      notify({ title:t("Viedään PDF..."), icon: "fas fa-file-export"})
    }
    else{
      notify({ title:t("Viedään Exceliin..."), icon: "fas fa-file-export"})
    }
    httpGetIncomeReportExcel(params,data).then(response => {
      let file = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      if (file_type === "pdf"){
        file = new Blob([response.data], { type: 'application/pdf' });
      }
      let fileURL = window.URL.createObjectURL(file);
      a.href = fileURL;
      a.download = fileName;
      a.click();
    })
    .finally(() => {
      setLoadingExport(false)
    })
  }
  //END
  

  // // Update subscription BEGIN

  const openUpdateUp = useCallback(() => {
    return (<>{account?.license?.name == "Lite" && <UpdateUp redirectToFrontpage={true}/>}</>)
  }, [])

  // END

  
  const getSecurities = () => {


    const params = {
      portfolio: filters?.portfolio,
      date_transaction__date__gte: filters?.date_transaction__date__gte ? moment(filters?.date_transaction__date__gte).format("YYYY-MM-DD") : undefined,
      sdate_transaction__date__lte: filters?.date_transaction__date__lte ? moment(filters?.date_transaction__date__lte).format("YYYY-MM-DD") : undefined,
      stock_market: filters?.stock_market ? filters?.stock_market : undefined,
      selected_portfolios: null,
      ordering: filters?.ordering
    }


      httpGetIncomeReportSummary(params).then(response => {
        // setProfitSummary(response?.data)

        if (response?.data){
          let options=  []
          const sellOptions = response?.data?.sell || []
          const buyOptions = response?.data?.buy || []
          const optionsSet = new Set()
        
          // Add unique sell items to the options set
          sellOptions.forEach(item => {
            const key = `${item.related_model_type}-${item.related_model_id}`
            if (!optionsSet.has(key)) {
              optionsSet.add(key)
              options.push({related_model_type: item?.related_model_type, related_model_id: item?.related_model_id, value:item?.related_model?.[0]?.name})
            }
          })
        
          // Add unique buy items to the options set
          buyOptions.forEach(item => {
            const key = `${item.related_model_type}-${item.related_model_id}`
            if (!optionsSet.has(key)) {
              optionsSet.add(key)
              options.push({related_model_type: item?.related_model_type, related_model_id: item?.related_model_id, value:item?.related_model?.[0]?.name})
            }
          })

          setSecurityOptions(options)
        }
        console.log(response)
        setLoading(false)
      })


    
  }


  useEffect(() => {
    // getSecurities()
    setFilters(s => ({
      ...s,
      stock_market: undefined,
      related_model_id: undefined,
      related_model_type: undefined
    }))
  }, [filters?.portfolio])
  
  useEffect(() => {
    getTransactions()

  }, [filters?.limit, filters?.offset, filters?.ordering ])
// }, [filters?.limit, filters?.offset, filters?.ordering, filters?.portfolio, filters?.related_model_type,filters?.related_model_id, filters?.stock_market, filters?.date_transaction__date__gte, filters?.date_transaction__date__lte, filters?.type ])
  
  //[...filters, remove:undefined]

  useEffect(() => {
    const selectedPortfolioIds = [];
    filters.selected_portfolios.forEach(item => selectedPortfolioIds.push(item.id));
    setFilters(s => ({
      ...s,
      portfolio: selectedPortfolioIds?selectedPortfolioIds:[portfolio?.id],
    }))
  }, [filters.selected_portfolios])
  

  const SummaryHeaders = [
    { label: t("tax_form_9A_name", "Nimi"), key: "stock__name", sortable: false},
    { label: t("tax_form_9A_batch_id", "Erän ID"), key: "batch_id", sortable: false},
    { label: t("tax_form_9A_quantity", "Kpl"), key: "quantity", sortable: false},
    { label: t("tax_form_9A_date_buy", "Hankittu"), key: "batch_original_purchase_date", sortable: false },
    { label: t("tax_form_9A_date_sell", "Luovutettu"), key: "date_transaction", sortable: false },
    
    // { label: t("tax_form_9A_label", "Tunnus"), key: "ticker_symbol", sortable: false},

    // { label: t("tax_form_9A_label", "Tunnus"), key: "stock__ticker_symbol", sortable: false},
    // { label: t("tax_form_9A_balance", "Määrä"), key: "quantity"},
    // { label: t("tax_form_9A_portfolio", "Salkku"), key: "portfolio"},
    { label: t("tax_form_9A_currency", "Valuutta"), key: "currency"},

    // { label: t("tax_form_9A_quantity", "Määrä"), key: "quantity", sortable: false},
    { label: t("tax_form_9A_price_total_sell", "Luovutushinta"), key: "price_total", sortable: false},
    { label: t("tax_form_9A_price_total_buy", "Hankintahinta"), key: "purchase_cost_use", sortable: false},
    // { label: t("tax_form_9A_commission_total", "Kulut"), key: "commission_total", sortable: false},
    { label: t("tax_form_9A_commission_total_buy", "Hankintakulut"), key: "commission_total_buy_use", sortable: false},
    { label: t("tax_form_9A_commission_total_sell", "Myyntikulut"), key: "commission_total_sell_use", sortable: false},
    { label: t("tax_form_9A_purchase_cost_default", "Hankintameno-olettama"), key: "assumption_costs", sortable: false},

    { label: t("tax_form_9A_profit_or_loss", "Voitto tai tappio"), key: "profit_use", sortable: false},
    // { label: t("tax_form_9A_actions", ""), key: "actions"},
  ];
  
  const paginationPrevious = () => {
    let offset = filters?.offset - filters?.limit;
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = filters?.offset + filters?.limit;
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  

  const genrateRows = (type) => {
    let buy_total_purchase_cost = 0
    let buy_total_price_summary = 0
    let buy_commission_total = 0
    let buy_value_total = 0
    let buy_profit = 0


    let sell_total_purchase_cost = 0
    let sell_total_price_summary = 0
    let sell_commission_total = 0
    let sell_profit = 0

    let sell_profit_positive = 0
    let sell_profit_negative = 0
    let sell_total_price_summary_negative = 0
    let sell_total_price_summary_positive = 0
    let sell_total_purchase_cost_negative = 0
    let sell_total_purchase_cost_positive = 0
    let sell_commission_total_buy_negative = 0
    let sell_commission_total_buy_positive = 0
    let sell_commission_total_sell_negative = 0
    let sell_commission_total_sell_positive = 0
    let sell_assumption_costs_negative = 0
    let sell_assumption_costs_positive = 0


    let rows = []


    if(type === "all" || type === "sell" || type==="summary"){

      //data rows
      profitSummary?.sell && profitSummary?.sell.map((row) => {
        const batch_year = new Date(row?.batch_original_purchase_date).getFullYear()
        const batch_month = new Date(row?.batch_original_purchase_date).getMonth()
        const batch_day = new Date(row?.batch_original_purchase_date).getDate()
        
        const date_transaction = new Date(row?.date_transaction)
        const batch_date_cut_off = new Date(batch_year + Number(cutOffYear), batch_month, batch_day)

        row.profit = row?.commission_total + Number(row?.price_total) + Number(row?.purchase_cost)
        row.profit_use = row?.profit
        row.purchase_cost_use = row?.purchase_cost
        row.commission_total_buy_use = row?.commission_total_buy
        row.commission_total_sell_use = row?.commission_total_sell
        row.assumption_costs = 0


        if (usePurchaseCostAssumption){
          let all_costs = (Number(row?.commission_total) + Number(row?.purchase_cost)) * -1
          let assumption_costs = 0
          // console.log("all_costs", all_costs)
          // console.log("row?.price_total", row?.price_total)
          // console.log("row?.purchase_cost", row?.purchase_cost)
          // console.log("assumption", purchaseCostAssumption, purchaseCostAssumptionCutOfYear)
          // console.log("batch_date_cut_off", batch_date_cut_off)
          // console.log("------------------------")

          if (date_transaction > batch_date_cut_off && purchaseCostAssumptionCutOfYear > purchaseCostAssumption){
             assumption_costs = Number(row?.price_total) * purchaseCostAssumptionCutOfYear / 100
          }
          else {
            assumption_costs = Number(row?.price_total) * purchaseCostAssumption / 100
          }
          // console.log("assumption_costs", assumption_costs)
          if (all_costs < assumption_costs){
            row.purchase_cost_use = 0
            row.commission_total_buy_use = 0
            row.commission_total_sell_use = 0 
            row.assumption_costs = assumption_costs
            row.profit_use = Number(row?.price_total) - assumption_costs
          }
          else{
            row.assumption_costs = 0
          }
        } 




        sell_total_purchase_cost += Number(row?.purchase_cost_use)
        sell_total_price_summary += Number(row?.price_total)
        sell_commission_total += Number(row?.commission_total)
        sell_profit += row?.profit_use

        if (row?.profit_use >= 0){
          sell_profit_positive += row?.profit_use
          sell_total_price_summary_positive += Number(row?.price_total)
          sell_commission_total_buy_positive += Number(row?.commission_total_buy_use)
          sell_commission_total_sell_positive += Number(row?.commission_total_sell_use)
          sell_total_purchase_cost_positive += Number(row?.purchase_cost_use)
          sell_assumption_costs_positive += Number(row?.assumption_costs)

        }
        else{
          sell_profit_negative += row?.profit_use
          sell_total_price_summary_negative += Number(row?.price_total)
          sell_commission_total_buy_negative += Number(row?.commission_total_buy_use)
          sell_commission_total_sell_negative += Number(row?.commission_total_sell_use)
          sell_total_purchase_cost_negative += Number(row?.purchase_cost_use)
          sell_assumption_costs_negative += Number(row?.assumption_costs)
        }

        



        if(type==="sell" || type==="all") {
          rows.push(row)
        }
      } ) 

    }




    if (type==="all" || type==="summary"){

            profitSummary && rows.push({
            stock__name: t("tax_table_progit_total","Voitot yhteensä"),
            purchase_cost_use: roundScaleDecimals(sell_total_purchase_cost_positive,2) ,
            profit_use: roundScaleDecimals(sell_profit_positive, 2),
            price_total: roundScaleDecimals(sell_total_price_summary_positive, 2),
            commission_total: roundScaleDecimals( sell_commission_total, 2),
            value_total: roundScaleDecimals(sell_profit, 2),
            commission_total_sell_use: roundScaleDecimals(sell_commission_total_sell_positive, 2),
            commission_total_buy_use: roundScaleDecimals(sell_commission_total_buy_positive, 2),
            assumption_costs: roundScaleDecimals(sell_assumption_costs_positive, 2),
            type: "summary",
            className: "bg-light"
            })
            profitSummary && rows.push({
              stock__name: t("tax_table_losses_total","Tappiot yhteensä"),
              purchase_cost_use: roundScaleDecimals(sell_total_purchase_cost_negative,2) ,
              profit_use: roundScaleDecimals(sell_profit_negative, 2),
              price_total: roundScaleDecimals(sell_total_price_summary_negative, 2),
              commission_total: roundScaleDecimals( sell_commission_total, 2),
              commission_total_sell_use: roundScaleDecimals(sell_commission_total_sell_negative, 2),
              commission_total_buy_use: roundScaleDecimals(sell_commission_total_buy_negative, 2),
              assumption_costs: roundScaleDecimals(sell_assumption_costs_negative, 2),
              value_total: roundScaleDecimals(sell_profit, 2),
              type: "summary",
              className: "bg-light"
              })
              profitSummary && rows.push({
                stock__name: t("tax_table_total","Tulos"),
                // purchase_cost_use: roundScaleDecimals(sell_total_purchase_cost,2) ,
                profit_use: roundScaleDecimals(sell_profit, 2),
                // price_total: roundScaleDecimals(sell_total_price_summary, 2),
                commission_total: roundScaleDecimals( sell_commission_total, 2),
                value_total: roundScaleDecimals(sell_profit, 2),
                type: "summary",
                className: "bg-light"
                })
            /*
            profitSummary && rows.push({
              stock__name: t("Kaikki yhteensä"),
              purchase_cost: roundScaleDecimals(buy_total_purchase_cost +sell_total_purchase_cost,2) ,
              profit: roundScaleDecimals(buy_profit +sell_profit, 2),
              price_total: roundScaleDecimals(buy_total_price_summary + sell_total_price_summary, 2),
              commission_total: roundScaleDecimals(buy_commission_total + sell_commission_total, 2),
              value_total: roundScaleDecimals(buy_value_total +sell_profit, 2),
              type: "summary",
              className: "bg-light"
              })*/
            }

    return rows
  }
  
  useEffect(() => {
    setPdfRows(genrateRows("sell"))
    setPdfSummaryRows(genrateRows("summary"))
  }, [profitSummary, currentDate, cutOffYear, purchaseCostAssumption, purchaseCostAssumptionCutOfYear, usePurchaseCostAssumption])

  const getSummaryTableRows = useCallback((type) => {
    
            
    
    const rows = genrateRows(type)


    return profitSummary && rows.map((row, index) => {
      const transactionTypeValue = row?.type
      let transactionTypeCell;
      if (transactionTypeValue == "2") {
        transactionTypeCell = (<span className="text-success font-weight-bold">{t("transactionTypeValue_2", transactionTypeValue)}</span>)
      }
      else if (transactionTypeValue == "3") {
        transactionTypeCell = (<span className="text-danger font-weight-bold">{t("transactionTypeValue_3", transactionTypeValue)}</span>)
      }
      else {
        transactionTypeCell = (<span className="text-info font-weight-bold"></span>)
      }
      const stockNameCell = (
        <><span className={row?.type== "summary" ? "font-weight-bolder": null} style={{opacity: row?.type == "space" ? 0 : 1 }}>{row?.type !="summary"?`${row?.related_model?.[0]?.name}`:row?.stock__name}</span></>
      )
      const comissionTotalCell = (
        <><span className={row?.type== "summary" ? "font-weight-bold": null}>{!row?.commission_total? "":thousandFormat(roundScaleDecimals(row?.commission_total, 2))}</span></>
      )
      const comissionTotalSellCell = (
        <><span className={row?.type== "summary" ? "font-weight-bold": null}>{!row?.commission_total_sell_use? "":thousandFormat(roundScaleDecimals(row?.commission_total_sell_use, 2))}</span></>
      )
      const comissionTotalBuyCell = (
        <><span className={row?.type== "summary" ? "font-weight-bold": null}>{!row?.commission_total_buy_use? "":thousandFormat(roundScaleDecimals(row?.commission_total_buy_use, 2))}</span></>
      )
      const profitCell = (
        <span className={row?.type== "summary" ? "font-weight-bolder": null}>
          {/\d/.test(row?.profit_use)? (
            <span className={row.profit_use < 0 ? "text-danger" : "text-success"}>
            {thousandFormat(roundScaleDecimals((row?.profit_use), 2))}
            </span>
          ):(
            <span className={ "text-danger"}>
            {row?.profit_use}
            </span>
          )
          }
        </span>
        
      )
      const priceTotalCell = (
        <span className={row?.type== "summary" ? "font-weight-bold": null}>
          {/\d/.test(row?.price_total)? (
            <span >
            {thousandFormat(roundScaleDecimals((row?.price_total), 2))}
          
            {type=="buy" && row?.type!="summary" && <span className="text-gray ml-2" > ({ getDate(row?.related_model?.[0]?.stock_date)})**</span>}
            </span>
          ):(
            <span className={ "text-danger"}>
            {row?.price_total}
            </span>
          )
          }
        </span>
      )
      const purchaseCostCell = (
        <><span className={row?.type== "summary" ? "font-weight-bold": row?.type =="space"? "pt-3":null} >{!row?.purchase_cost_use ? "":thousandFormat(roundScaleDecimals(row?.purchase_cost_use,2))}</span></>

      )      
      const dateTransactionCell = (
        <>
          <div
            className={row?.type== "summary" ? "font-weight-bold": row?.type =="space"? "pt-3":null} 
            style={{opacity: row?.type == "space" ? 0 : 1,  }}
          >
          {
            /\d/.test(row?.date_transaction)? 
            <>
            {row?.original_purchase_date? <> {getDate(row?.original_purchase_date) + " " }<span className="text-gray ml-1 ">({getDate(row?.date_transaction)})*</span></>:getDate(row?.date_transaction)}
            </>: 
             <div style={{ maxWidth: '140px', whiteSpace: 'pre-wrap',overflowWrap: 'break-word'  }}>         
          {row?.original_purchase_date? <> {getDate(row?.original_purchase_date) + " " }<span className="text-gray ">({getDate(row?.date_transaction)}*)</span></>:row?.date_transaction}
          </div>
          }
          </div>
        </>


      )
      return {
        ...row,
        date_transaction: dateTransactionCell,
        batch_original_purchase_date: getDate(row?.batch_original_purchase_date),
        // quantity: row?.quantity && roundScaleDecimals(row?.quantity,2),
        quantity: row?.quantity && {
          content: row?.quantity && thousandFormat(roundScaleDecimals(row?.quantity,2)),
          className: "text-right"
        },
        price_total: {
          content: priceTotalCell,
          className: "text-right"
        },
        purchase_cost_use: {
          content: purchaseCostCell,
          className: "text-right"
        },
        commission_total_sell_use: {
          content: comissionTotalSellCell,
          className: "text-right"
        },
        commission_total_buy_use: {
          content: comissionTotalBuyCell,
          className: "text-right"
        },
        commission_total: {
          content: comissionTotalCell,
          className: "text-right"
        },
        profit_use: {
          content: profitCell,
          className: "text-right"
        },
        assumption_costs: row?.assumption_costs ? {
        content:  thousandFormat(roundScaleDecimals(row?.assumption_costs,2)),
          className: "text-right"
        }:null,

        ticker_symbol: row?.related_model?.[0]?.ticker_symbol,
        // commission_total_sell_use: comissionTotalSellCell,
        // commission_total_buy_use: comissionTotalBuyCell,
        stock__name: stockNameCell,
        currency:  filters?.portfolio.length > 1? "EUR": getPortfolioCurrency(filters?.portfolio[0]),
        portfolio: getPortfolioName(row?.portfolio),
        // purchase_cost_use: purchaseCostCell,
        // assumption_costs: row?.assumption_costs && roundScaleDecimals(row?.assumption_costs,2),
        // price_total: priceTotalCell,
        // commission_total: comissionTotalCell,
        // profit_use: profitCell,
        type: transactionTypeCell,
        stock_market: row?.stock_market,
        actions:(
                    <><Button className="btn-sm" color="secondary" >{t("edit_button","Muokkaa")}</Button></>
                ) 
      }
    })
  }, [profitSummary, currentDate, cutOffYear, purchaseCostAssumption, purchaseCostAssumptionCutOfYear, usePurchaseCostAssumption])



   



  
  // Filters
  
  const handleChange = ({target}, child) => {
    setFilters(s => ({
      ...s,
      [target?.name]: target.value
    }))
   

  }

  const setFieldValue = (name, value) => {
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  const setSelectFieldValue = (name, value) => {
    setFilters(s => ({
      ...s,
      [name]: value?.id > 0 ? value?.id : undefined
    }))
  }
  
  const getFormikInputProps = useCallback(
    name => ({
      name,
      value: filters[name],
      onChange: handleChange
    }),
    [filters, handleChange]
  );
  
  const handleStockMarketChange = ({target}) => {
    
    setFilters(s => ({
      ...s,
      stock_market: target.value
    }))
  }

  const handleInvestmentTypeChange = ({target}) => {
    if (target.value !== "10" ) {
      setFilters(s => ({
        ...s,
        related_model_type: target.value,
        stock_market: undefined,
        related_model_id: undefined
      }))

    }
    else {
    setFilters(s => ({
      ...s,
      related_model_type: target.value,
      related_model_id: undefined
    }))
  }
  }

    const handlePortfolioChange = ({target}) => {
    if (target.value !== "10" ) {
      setFilters(s => ({
        ...s,
        [target?.name]: target.value,
        related_model_type: undefined,
        stock_market: undefined,
        related_model_id: undefined
      }))

    }
    else {
    setFilters(s => ({
      ...s,
      [target?.name]: target.value,
      related_model_type: undefined,
      related_model_id: undefined
    }))
  }
  }

  const handleSecurityChange = (e) => {
    console.log(e)
    const target = e?.target
    console.log(target)
   console.log(target?.related_model_type)
   const [related_model_type, related_model_id] = target.value.split('-');

    setFilters(s => ({
      ...s,
      related_model_type: related_model_type,
      related_model_id: related_model_id
    }))
  }
  const handleTimeRangeChange = ({target}, e) => {
    console.log(target)

    const {key, value, range} =JSON.parse(target?.value)
    console.log(range)
    const startDate = range? new Date(range?.start): null;
    const endDate = range?.end? new Date(range?.end): null;           
    setFilters(s => ({
      ...s,
      [target?.name]: target?.value,
      date_transaction__date__gte: startDate? startDate: null,
      date_transaction__date__lte: endDate? endDate: null,
    }))
  }

  const [modalEditOpen, setModalEditOpen] = useState(false)
  useEffect(() => {
    // if (portfolio == null || portfolio == undefined) {
    //   setModalEditOpen(true)
    // }

    if (portfoliosLoading) {
      setModalEditOpen(false)
    }
    else {
    setModalEditOpen(portfolios.length === 0 ? true : false)
    }
  }, [portfolios])

  const [modalEditId, setModalEditId] = useState()
  
  const toggleModalEdit = () => {
    setModalEditOpen(s => !s);
  }
  
  const openModalEdit = (id) => {
    setModalEditOpen(true)
    setModalEditId(id);
  }
  
  const modalEditResolve = () => {
    setModalEditOpen(false);
    setModalEditId(null);
    
  }
  
  const modalEditReject = () => {
    setModalEditOpen(false);
    setModalEditId(null);
  }

  
  const getStockSelect = () => {
    if (filters?.stock_market === "PRIVATE") {
      return (
        <StockPrivateSelect
          {...getFormikInputProps("stock_private_obj")}
          label={t("purchase_order_edit_asset_stock", "Osake (Stock)")}
          setFieldValue={setSelectFieldValue}
        />
      )
    }
    else {
      return (
        <StockSelect
          {...getFormikInputProps("stock")}
          label={t("purchase_order_edit_asset_stock", "Osake (Stock)")}
          setFieldValue={setSelectFieldValue}
          showAll={true}
          setValueToSelect={true} 
        />
      )
    }
  }

  const getInvestmentTypeSelect = useCallback(() => {
   return <InvestmentTypeSelect name="related_model_type" onChange={handleInvestmentTypeChange} value={filters.related_model_type} />
  }, [filters?.related_model_type])

  const getSecuritySelect = useCallback((options) => {
   return <SecuritySelect name="security_select" onChange={handleSecurityChange} value={filters.related_model_type && filters.related_model_id ?`${filters.related_model_type}-${filters.related_model_id}`: ""} options={securityOptions} />
  }, [filters?.related_model_type, filters?.related_model_id, securityOptions])

  const getTimeRangeSelect = useCallback(() => {
    return<TimeRangeSelect name="time_range" onChange={handleTimeRangeChange} value={filters?.time_range} />
   }, [filters?.time_range])



   useEffect(() => {
  
    setExportButton(  
      <>
      <Button color="secondary" size="sm" disabled={loadingExport} onClick={() => handleExcelExport("excel")}>
          <i className="fas fa-file-excel"></i>&nbsp;
          {t("excel_export","Vie (Excel)")}
      </Button>
      {/* <Button color="secondary" size="sm" disabled={loadingExport} onClick={() => handleExcelExport("pdf")}>
        <i className="fas fa-file-pdf"></i>&nbsp;
        {t("pdf_export","Vie (PDF)")}
      </Button> */}
      </>             
      )
    }, [filters, usePurchaseCostAssumption, purchaseCostAssumption, purchaseCostAssumptionCutOfYear, cutOffYear, loadingExport])


  const getPortfolioSelect = useCallback(() => {
    return (
      <>
        <PortfolioSelect 
          name="selected_portfolios"
          label={t("Salkku")}
          setFieldValue={setFieldValue}
          multiple={true}
          placeholder={t("select_portfolio", "Valitse salkku")}
          onChange={handlePortfolioChange}
          value= {filters.selected_portfolios}
        />
      </>
    )
  }, [portfolio, filters?.selected_portfolios])
    
  
  return (
    
    <div className="sk_transaktions_list">
      <PageContainer>
       {openUpdateUp()}


        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex text-right align-items-center">
            {/* 
            <Input label="Kurssipäivä" name="stock_date" type="date" value={currentDate} onChange={handleDateChange} />
            */}
          </div>
        </div>
        

        <Container fluid className="custom-no-padding  mt-3 m-0 mr-0 px-0" style={{paddingLeft:"0px !important", paddingRight: "0px !important"}}>
        <Row className=" m-0 p-0">
            
          <Col className="m-0 p-0">

          <div className="d-flex flex-wrap ">
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '150px',  maxWidth: '320px'}}>
                {getPortfolioSelect()}
              </div>


              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '150px',  maxWidth: '220px'}}>
                 {getSecuritySelect()}
              </div>
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '130px', maxWidth:'140px'}}>
                {getTimeRangeSelect()}
              </div>
              <div className="d-flex justify-content-start align-items-end py-2 pr-2 mr-3" style={{minWidth: '100px', maxWidth: '130px'}}>
                <div>

                <label className="mb-1 pr-1 header-bold">{t("date_transaction_from", "Päivä alkaen")}</label>
                <DatePicker
                  className="form-control"
                  selected={filters?.date_transaction__date__gte}
                  name="date_transaction__date__gte"
                  onChange={
                    (date) => {
                      setFieldValue("date_transaction__date__gte", date)
                      setFieldValue("time_range", "")
                    }
                  }
                  showYearDropdown
                  dateFormat="d.M.yyyy"
                  isClearable={true}
                  placeholderText={t("Kaikki")}
                  
                  
                  />
                  </div>
              </div>
              <div className="d-flex justify-content-row align-items-end py-2 pr-2 mr-3" style={{minWidth: '100px', maxWidth: '130px'}}>
                <div>
                  <label className="mb-1 pr-1 header-bold">{t("date_transaction_to", "Päivä päättyen")}</label>
                  <DatePicker
                    className="form-control"
                    selected={filters?.date_transaction__date__lte}
                    name="date_transaction__date__lte"
                    onChange={
                      (date) => {
                        setFieldValue("date_transaction__date__lte", date)
                        setFieldValue("time_range", "")
                      }
                    }
                    showYearDropdown
                    dateFormat="d.M.yyyy"
                    isClearable={true}
                    placeholderText={t("Kaikki")}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-end py-2 pr-2  ml-0 mr-3 ml-xxl-auto mr-xxl-0" style={{minWidth: '50px'}}>
                <Button name="profit_search_button" className="mt-2" color="secondary" onClick={() => getTransactions()}>{t("Hae")}</Button>
              </div>

          </div>

          </Col>
        </Row>
        </Container>








        <Container fluid className="custom-no-padding bg-lighter mt-3 m-0 mr-0 px-0" style={{paddingLeft:"0px !important", paddingRight: "0px !important"}}>
        <Row className=" m-0 p-0">
            
          <Col className="m-0 p-0">
            <div className="d-flex flex-wrap ">
              <div className="d-flex justify-content-start align-items-center py-2 pr-2 mr-3" style={{minWidth: '150px'}}>
                <Typography variant="medium" className=" mr-2 text-dark">{t("tax_table_filter_use_tax","Käytä hankintameno-olettamaa")}</Typography>
                <Checkbox 
                id="fifo"
                name="fifo_used"
                checked={usePurchaseCostAssumption}
                onChange={(e) => {setUsePurchaseCostAssumption(e.target.checked)}}
                className="zIndexZero"
                />
              </div>
              <div className="d-flex justify-content-start align-items-center  py-2 pr-2 mr-3" style={{minWidth: '150px'}}>
                <Typography variant="medium" className=" mr-2 text-dark">{t("tax_table_filter_assumption_year_occur","Korotettu olettama raja")}</Typography>
                <Input label={t(" ")} name="cut_off_year" type="number" value={cutOffYear} onChange={(e) => setCutOffYear(e.target.value)}  style={{maxWidth: '60px'}}/>
                <Typography variant="medium" className=" ml-2  text-dark">{t("v.")}</Typography>
              </div>
              <div className="d-flex justify-content-start align-items-center  py-2 pr-2 mr-3" style={{minWidth: '150px'}}>
                <Typography variant="medium" className=" mr-2 text-dark">{t("tax_table_filter_assumption_least","Olettama aina vähintään")}</Typography>
                <Input label={t(" ")} name="purchase_cost_assumption" type="number" value={purchaseCostAssumption} onChange={(e) => setPurchaseCostAssumption(e.target.value)}  style={{maxWidth: '60px'}}/>
                <Typography variant="medium" className=" ml-2  text-dark">{t("%")}</Typography>
              </div>

              <div className="d-flex justify-content-start align-items-center  py-2 pr-2 mr-3" style={{minWidth: '150px'}}>
                <Typography variant="medium" className=" mr-2 text-dark">{t("tax_table_filter_assumption_ownership_time", `Olettama kun omistus vähintään`)} {cutOffYear} {t(`vuotta` )}</Typography>
                <Input label={t(" ")} name="cut_off_year_cut_off" type="number" value={purchaseCostAssumptionCutOfYear} onChange={(e) => setPurchaseCostAssumptionCutOfYear(e.target.value)}  style={{minWidth: "50px",maxWidth: '60px'}}/>
                <Typography variant="medium" className=" ml-2 text-dark">{t("%")}</Typography>
              </div>
              <div className="d-flex justify-content-start align-items-end py-2 pr-2  ml-0 mr-3 ml-xxxl-auto mr-xxxl-0" style={{minWidth: '50px'}}>
                {/* <Button color="secondary" size="sm" disabled={loadingExport} onClick={ExcelExport}>
                    <i className="fas fa-file-excel"></i>&nbsp;
                    {t("tax_report_excel_export","Veroilmoitus Excel vienti")}
                </Button> */}
              </div>
            </div>
          </Col>
        </Row>
        </Container>
        <IncomeSummaryTable
          showId={false}
          checkboxes={false}
          headers={SummaryHeaders}
          headerColor={"light"}
          rows_sell={getSummaryTableRows("sell")}
          rows_buy={null}
          rows_summary={getSummaryTableRows("summary")}
          loading={loading}
          showBuy={false}
        />

        
        

      </PageContainer>
        {/*

        <Typography  variant="small" className="mt-5 text-gray">
        * {t("Erän vaihtopäivämäärä")}
        </Typography>
        
        <Typography  variant="small" className="mt-1 text-gray">
        ** {t("Viimeisimmän kurssikirjauksen pvm")}
        </Typography>
        
      */}


      <Modal
        // title={modalEditPurchaseId > 0 ? t("Muokkaa ostokirjausta") : t("Uusi ostokirjaus")}
        isOpen={modalEditOpen}
        toggleModal={toggleModalEdit}
        size="modal-lg"
      >
          <Info
          objectId={modalEditId}
          handleResolve={modalEditResolve}
          handleReject={modalEditReject}
        />
      </Modal>

    </div>
  );
}

export default TaxReport9A;
