import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio, DatePicker } from "stories/forms";
import { useNotification } from "stories/components/Notification"

import DerivativeTypeInput from "components/Forms/DerivativeTypeInput/DerivativeTypeInput"
import OptionRightInput from "components/Forms/OptionRightInput/OptionRightInput"
import OptionAssetTypeInput from "components/Forms/OptionAssetTypeInput/OptionAssetTypeInput"
import OptionRoleInput from "components/Forms/OptionRoleInput/OptionRoleInput"
import OptionStateInput from "components/Forms/OptionStateInput/OptionStateInput"
import StockSelect from "components/Forms/StockSelect/StockSelect"
import StockPrivateSelect from "components/Forms/StockPrivateSelect/StockPrivateSelect"
import StockMarketSelect from "components/Forms/StockMarketSelect/StockMarketSelect"
import PrivateInvestmentFundSelect from "components/Forms/PrivateInvestmentFundSelect/PrivateInvestmentFundSelect"
import CurrencySelect from "components/Forms/CurrencySelect/CurrencySelect"
import { round } from "services/helpers"

import { 
  httpGetOption, 
  httpCreateOption, 
  httpUpdateOption, 
  httpDeleteOption, 
} from "services/options"

const defaultValues = {
  id: null,
  derivative_type: 2,
  asset_type: 2,
  option_right: "2",
  spot_price_manual: 0.0,
  strike_price: 0.0,
  lot_size: 1,
  lot_amount: 1,
  currency_1: "EUR",
  currency_2: "USD",
  exercise_price: 0.0,
}

const DECIMAL_PLACES = 6;

const OptionEdit = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      stockMarket,
      setStockMarket,
      getBasicDataObjects,
      objectId
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handlePremiumChange = ({target}, child) => {
    const premium = target.value.toString().replace(",",".")
    setFieldValue("premium", premium)
    if (values?.quantity) {
      const premium_total = round(parseFloat(premium) * parseInt(values?.quantity), DECIMAL_PLACES)
      setFieldValue("premium_total", premium_total)
    }
  }
  
  const handlePremiumTotalChange = ({target}, child) => {
    const premium_total = target.value.toString().replace(",",".")
    setFieldValue("premium_total", premium_total)
    if (values?.quantity) {
      const premium = round(parseFloat(premium_total) / parseInt(values?.quantity), DECIMAL_PLACES)
      setFieldValue("premium", premium)
    }
  }
  
  useEffect(() => {
    const lot_size = parseInt(values?.lot_size)
    const lot_amount = parseInt(values?.lot_amount)
    const quantity = lot_size * lot_amount
    setFieldValue("quantity", quantity)
  }, [values?.lot_size, values?.lot_amount])
  
  useEffect(() => {
    if (values?.quantity && values?.premium)  {
      const premium_total = parseInt(values?.quantity) * parseFloat(values?.premium)
      setFieldValue("premium_total", premium_total)
    }
  }, [values?.quantity])
  
  const resolveStockPublic = (params) => {
    if (params?.name) {
      setFieldValue("asset_name", params?.name)
    }
  }
  
  const resolveStockPrivate = (params) => {
    if (params?.name) {
      setFieldValue("asset_name", params?.name)
    }
  }
  
  const resolvePrivateInvestmentFund = (params) => {
    if (params?.name) {
      setFieldValue("asset_name", params?.name)
    }
  }
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(res => {
      if (Object.keys(res).length === 0) {
        handleSubmit()
      }
      else {
        setTouched(res);
        setErrors({
          ...res,
          general: t("Please check the form errors", "Tarkista lomakkeen virheet")
        })
      }
    })
  }

  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleConfirm = () => {
    /*
    httpConfirmPurchaseOrderShipping(values?.id).then(res => {
      handleSubmit()
    })
    */
  }
  
  const handleStockMarketChange = ({target}) => {
    setStockMarket(target.value)
  }
  
  useEffect(() => {
    getBasicDataObjects({stock_market:stockMarket})
  }, [stockMarket])
  
  const stocks = useStoreState((state) => state.stocks?.objects);
  const privateStocks = useStoreState((state) => state.privateStocks?.objects);
  const private_investment_funds = useStoreState((state) => state.privateInvestmentFunds?.objects);

  
  
  const getAssetTypeSelect = useCallback(() => {
    // Apufunktio joka hakee arvon oikeasta listasta
    const getAssetValue = () => {
      if (!values?.asset_id) return null;
      
      let asset = null;
      if (values?.asset_type == 2) {
        asset = stocks?.find(s => s.id === values.asset_id);
      } 
      else if (values?.asset_type == 6) {
        asset = privateStocks?.find(s => s.id === values.asset_id);
      }
      else if (values?.asset_type == 10) {
        asset = private_investment_funds?.find(s => s.id === values.asset_id);
      }

      if (!asset) return null;
      return {
        value: asset.id,
        label: asset.name,
      };
    };

    if (values?.asset_type == 2) {
      return (
        <>
          <StockMarketSelect 
            onChange={handleStockMarketChange} 
            value={stockMarket} 
            showPrivate={false} 
          />
          <StockSelect
            {...getFormikInputProps("asset_id")}
            value={getAssetValue()}
            label={t("option_edit_asset_stock", "Stock")}
            setFieldValue={setFieldValue}
            setFieldValueReturnId={true}
            handleResolve={resolveStockPublic}
            required={true}
          />
        </>
      )
    }
    else if (values?.asset_type == 6) {
      return (
        <StockPrivateSelect
          {...getFormikInputProps("asset_id")}
          label={t("option_edit_private_stock", "Stock")}
          setFieldValue={setFieldValue}
          handleResolve={resolveStockPrivate}
        />
      )
    }
    else if (values?.asset_type == 10) {
      return (
        
        <PrivateInvestmentFundSelect
          {...getFormikInputProps("asset_id")}
          value={getAssetValue()}
          label={t("option_edit_private_investment_fund", "Investment fund")}
          setFieldValue={setFieldValue}
          handleResolve={resolvePrivateInvestmentFund}
          />
      )
    }
    else if (values?.asset_type == 12) {
      return (
        <>
          <CurrencySelect
            {...getFormikInputProps("currency_1")} 
            label={t("option_edit_currency_1", "Currency")}
            required
            disabled
          />
          <CurrencySelect
            {...getFormikInputProps("currency_2")} 
            label={t("option_edit_currency_2", "Currency")}
            required
          />
        </>
      )
    }
    else {
      return null;// t("No data available","Ei dataa saatavilla")
    }
  }, [values?.asset_type, stockMarket, values?.asset_id, stocks, privateStocks, private_investment_funds])
  
  useEffect(() => {
    if (values?.asset_type == 12) {
      setFieldValue("asset_name", `${values?.currency_1}-${values?.currency_2}`)
    }
    
  }, [values?.asset_type, values?.currency_1, values?.currency_2])
  
  const handleDelete = () => {
    const result = window.confirm(t("are_you_sure_window_confirm","Are you sure?"));
    if (result) {
      httpDeleteOption(values?.id).then(() => {
        props.handleResolve()
      })
    }
  }
  
  useEffect(() => {
    if (!objectId){
      setFieldValue("asset_id", null);
      setFieldValue("asset_name", "");
    }
  }, [values?.asset_type]);
  
  return (
    <>
      <Row  className="pb-3 pt-2 border-top">
        <Col xs={12} md={6} className="border-right px-4 pt-2">

          <Row>
            <Col className="mb-3">
              <DerivativeTypeInput
                {...getFormikInputProps("derivative_type")}
                label={t("option_edit_derivative_type", "Derivative type")}
                allOption={false}
              />
            </Col>
          </Row>
        
          <Row>
            <Col className="mb-3">
              <OptionAssetTypeInput
                {...getFormikInputProps("asset_type")}
                label={t("option_edit_asset_type", "Asset type")}
                allOption={false}
              />
            </Col>
          </Row>

          <Row>
            <Col className="mb-3">
            { getAssetTypeSelect() }
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("asset_name")}
                label={t("option_edit_name", "Name")}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("trade_id")}
                label={t("option_edit_trade_id", "Trade id")}
              />
            </Col>
          </Row>
          {values?.derivative_type == 2 && 
            <Row>
            <Col className="mb-3">
              <OptionRightInput
                {...getFormikInputProps("option_right")}
                label={t("option_edit_right", "Right")}
                allOption={false}
                />
            </Col>
          </Row>
              }
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("owner_name")}
                label={t("option_edit_owner", "Owner/Buyer")}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("writer_name")}
                label={t("option_edit_writer", "Writer/Seller")}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <OptionRoleInput
                {...getFormikInputProps("role")}
                label={t("option_edit_role", "Role")}
                allOption={false}
              />
            </Col>
          </Row>
          

          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("lot_size")}
                label={t("option_edit_lot_size", "Lot size")}
                type="number"
                min={1}
              />
            </Col>
          </Row>
          </Col>
        <Col className="px-4 pt-2">
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("lot_amount")}
                label={t("option_edit_lot_amount", "Lots amount")}
                type="number"
                min={1}
              />
            </Col>
          </Row>

          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("quantity")}
                label={t("option_edit_quantity", "Quantity")}
                type="number"
                min={0}
                disabled
              />
            </Col>
          </Row>


          
          <Row>
            <Col className="mb-3">
              <DatePicker
                {...getFormikInputProps("start_date")}
                label={t("option_edit_start_date", "Start date")}
                className="form-control"
                selected={values?.start_date}
                name="start_date"
                onChange={(date) => setFieldValue("start_date", date)}
                showYearDropdown
                dateFormat="d.M.yyyy"
                value={values?.start_date}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <DatePicker
                {...getFormikInputProps("end_date")}
                label={t("option_edit_end_date", "Expiry date")}
                className="form-control"
                selected={values?.end_date}
                name="end_date"
                onChange={(date) => setFieldValue("end_date", date)}
                showYearDropdown
                dateFormat="d.M.yyyy"
                value={values?.end_date}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("premium")}
                onChange={handlePremiumChange}
                label={t("option_edit_premium", "Premium / share")}
                type="decimal"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("premium_total")}
                onChange={handlePremiumTotalChange}
                label={t("option_edit_premium_total", "Premium total")}
                type="decimal"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("other_expenses")}
                label={t("option_edit_other_expenses", "Muut kulut")}
                type="decimal"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("strike_price")}
                label={t("option_edit_strike_price", "Strike price")}
                type="decimal"
              />
            </Col>
          </Row>
          
          {/*
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("spot_price_manual")}
                label={t("option_edit_spot_price", "Spot price (Ajankohtainen hinta)")}
                type="number"
                step="0.01"
                min={0}
                helpText={t("If live spot price not available")}
              />
            </Col>
          </Row>
          */}
          
          <Row>
            <Col className="mb-3">
              <OptionStateInput
                {...getFormikInputProps("option_state")}
                label={t("option_edit_state", "State")}
                allOption={false}
              />
            </Col>
          </Row>
          
          { values?.option_state && values?.option_state > 2 ? (
            <Row>
              <Col className="mb-3">
                <label className="mb-1 pr-1 header-bold">{t("option_edit_state_date", "State date")}</label>
                <DatePicker
                  className="form-control"
                  selected={values?.option_state_date}
                  name="option_state_date"
                  onChange={(date) => setFieldValue("option_state_date", date)}
                  showYearDropdown
                  dateFormat="d.M.yyyy"
                  value={values?.option_state_date}
                />
              </Col>
            </Row>
          ) : null }
          
          { values?.option_state && values?.option_state == 3 ? (
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("exercise_price")}
                  label={t("option_edit_exercise_price", "Exercise price")}
                  type="decimal"
                />
              </Col>
            </Row>
          ) : null}
          
          { values?.option_state && values?.option_state == 5 ? (
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("termination_fee")}
                  label={t("option_edit_termination_fee", "Termination price")}
                  type="decimal"
                />
              </Col>
            </Row>
          ) : null}
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("notes")}
                label={t("option_edit_notes", "Notes")}
                type="textarea"
              />
            </Col>
          </Row>
          
        </Col>

      </Row>
      
      <Row>
        <Col>{/*JSON.stringify(values)*/}</Col>
      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="default">{t("close_button","Close")}</Button>
        </Col>
        { values?.id ? (
        <Col className="mb-3 text-center">
          <Button onClick={handleDelete} variant="danger">{t("delete_button","Delete")}</Button>
        </Col>
        ) : null }
        <Col className="mb-3 text-right">
          { !preSetValues?.state_delivery && (
            <Button variant="secondary" disabled={isSubmitting} onClick={onSubmit}>
              {t("Save","Save")}
            </Button>
          )}
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        
    </>
  );
}

const OptionEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("validation_required_field","The field is required");
      return Yup.object().shape({
        asset_name: Yup.string().required(required).nullable(),
        asset_id: Yup.number().required(required).nullable(),
        end_date: Yup.string().required(required).nullable(),
        owner_name: Yup.string().required(required).nullable(),
        start_date: Yup.string().required(required).nullable(),
        writer_name: Yup.string().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, addOption, portfolio} = props;
      
      const values_premium = values?.premium ? round(parseFloat(values?.premium.toString().replace(",",".")), DECIMAL_PLACES) : 0;
      const values_premium_total = values?.premium_total ? round(parseFloat(values?.premium_total.toString().replace(",",".")), DECIMAL_PLACES) : 0;
      const values_other_expenses = values?.other_expenses ? round(parseFloat(values?.other_expenses.toString().replace(",",".")), DECIMAL_PLACES) : 0;
      const values_termination_fee = values?.termination_fee ? round(parseFloat(values?.termination_fee.toString().replace(",",".")), DECIMAL_PLACES) : 0;
      const values_strike_price = values?.strike_price ? round(parseFloat(values?.strike_price.toString().replace(",",".")), DECIMAL_PLACES) : 0;
      const values_exercise_price = values?.exercise_price ? round(parseFloat(values?.exercise_price.toString().replace(",",".")), DECIMAL_PLACES) : 0;

      //convert start_date and end_date to 12:00:00
      let start_date = values?.start_date;
      const oldStartDate = values?.start_date;
      const newStartDate = new Date(oldStartDate.getFullYear(), oldStartDate.getMonth(), oldStartDate.getDate(), 12, 0, 0);
      const formattedStartDate = newStartDate.toISOString().split('T')[0] +" 12:00:00Z";;
      start_date = formattedStartDate;

      let end_date = values?.end_date;
      const oldEndDate = values?.end_date;
      const newEndDate = new Date(oldEndDate.getFullYear(), oldEndDate.getMonth(), oldEndDate.getDate(), 12, 0, 0);
      const formattedEndDate = newEndDate.toISOString().split('T')[0] +" 12:00:00Z";;
      end_date = formattedEndDate;

      let option_state_date = values?.option_state_date
      if (option_state_date){
        const oldOptionStateDate = values?.option_state_date;
        const newOptionStateDate = new Date(oldOptionStateDate.getFullYear(), oldOptionStateDate.getMonth(), oldOptionStateDate.getDate(), 12, 0, 0);
        const formattedOptionStateDate = newOptionStateDate.toISOString().split('T')[0] +" 12:00:00Z";;
        option_state_date = formattedOptionStateDate;
      }
      
      let data = {
        ...values,
        stock: values?.stock_private_obj?.id,
        portfolio: portfolio?.id,
        // start_date: values?.start_date ? moment(values?.start_date).format() : null, 
        // end_date: values?.end_date ? moment(values?.end_date).format() : null,
        // option_state_date: values?.option_state_date ? moment(values?.option_state_date).format() : null,
        start_date: start_date, 
        end_date: end_date,
        option_state_date: option_state_date,
        exercise_date: values?.exercise_date ? moment(values?.exercise_date).format() : null,
        premium: values_premium,
        premium_total: values_premium_total,
        other_expenses: values_other_expenses,
        termination_fee: values_termination_fee,
        strike_price: values_strike_price,
        exercise_price: values_exercise_price,
      };
      
      // Convert data by role in the contract
      
      // Expenses always negative
      data.other_expenses = -Math.abs(data?.other_expenses)
      
      if (data?.role == 2) { // Buyer
        data.premium = -Math.abs(data?.premium)
        data.premium_total = -Math.abs(data?.premium_total)
        // data.termination_fee = Math.abs(data?.termination_fee)
      }
      else if (data?.role == 3) { // Seller
        data.premium = Math.abs(data?.premium)
        data.premium_total = Math.abs(data?.premium_total)
        // data.termination_fee = -Math.abs(data?.termination_fee)
      }
      

      
      if (values?.id) {
        httpUpdateOption(values.id, data).then(res => {
          console.log("httpUpdateOption res", res)
          setSubmitting(false);
          props.notify({ title:t("derivative_title","Derivative"), message:t("updated_message","Updated")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (error?.data) {
            setErrors({
              ...error?.data,
            })
          }
          else {
            setErrors({
              general: t("error_unknown","Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      else {
        // addOption(data)





        httpCreateOption(data).then(res => {
          setSubmitting(false);
          console.log("httpCreateOption res", res)
          props.notify({ title:t("derivative_title","Derivative"), message:t("created_message","Created")})
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (error?.data) {
            setErrors({
              ...error?.data,
            })
          }
          else {
            setErrors({
              general: t("error_unknown","Unknown error. Please try again later or contact support.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(OptionEdit)
    
    
const OptionEditView = ({history, objectId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [stockMarket, setStockMarket] = useState("XHEL");
  
  // Store: States
  const portfolio = useStoreState((state) => state.portfolio?.portfolio);
  const options = useStoreState((state) => state.options?.options);
  const stocks = useStoreState((state) => state.stocks?.objects);
  const privateStocks = useStoreState((state) => state.privateStocks?.objects);
  const private_investment_funds = useStoreState((state) => state.privateInvestmentFunds?.objects);
  
  // Store: Actions
  const addOption = useStoreActions((actions) => actions.options.addOption);
  const getBasicDataObjects = useStoreActions((actions) => actions.stocks.getObjects);
  
  const getObject = (id) => {
    
    setLoading(true)
    
    httpGetOption(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
        start_date: res?.data?.start_date ? moment(res?.data?.start_date).toDate() : null,
        end_date: res?.data?.end_date ? moment(res?.data?.end_date).toDate() : null,
        exercise_date: res?.data?.exercise_date ? moment(res?.data?.exercise_date).toDate() : null,
        option_state_date: res?.data?.option_state_date ? moment(res?.data?.option_state_date).toDate() : null,
      });
    
    }).finally(response => {
      setLoading(false);
    })
    
  }
  
  
  useEffect(() => {
    if (objectId && parseInt(objectId) > -1) {
      getObject(objectId)
    }
  }, [objectId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <OptionEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      objectId={objectId} 
      addOption={addOption}
      portfolio={portfolio}
      stockMarket={stockMarket}
      setStockMarket={setStockMarket}
      getBasicDataObjects={getBasicDataObjects}
      {...rest} 
    />
  )
  

}
  

export default OptionEditView;
